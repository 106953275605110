import React from "react";
import { headlineSize } from "~components/typography/H1";

interface Props {
	author?: string;
	text: string;
	className?: string;
	size?: "big" | "normal";
	align?: "center" | "left";
}

const Blockquote: React.FC<Props> = ({ author, text, className, size = "big", align = "center" }) => {
	return (
		<figure className={`${align === "center" ? "text-center" : ""} ${className ? className : ""}`}>
			<blockquote
				className={`${author ? "mb-10" : ""} italic ${size === "big" ? headlineSize : ""} font-serif text-gray-600`}
			>
				{text}
			</blockquote>
			{author ? <figcaption className="text-xl text-gray-600">~ {author} ~</figcaption> : null}
		</figure>
	);
};

export default Blockquote;
