import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import H2 from "~components/typography/H2";
import SubTitle from "~components/typography/SubTitle";
import CardNavigation from "~components/cards/CardNavigation";

const InvestInYourSelf: React.FC = () => {
	const data = useStaticQuery(graphql`
		{
			imageCardNavigationWorkshops: file(
				relativePath: { eq: "components/sections/InvestInYourSelf/invest-in-your-self_workshops.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			imageCardNavigationCoaching: file(
				relativePath: { eq: "components/sections/InvestInYourSelf/invest-in-your-self_coaching.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			imageCardNavigationSpeaker: file(
				relativePath: { eq: "components/sections/InvestInYourSelf/invest-in-your-self_speaker.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	const cardNavigation = [
		{
			image: data.imageCardNavigationWorkshops,
			title: "Workshops",
			description: "Mit unseren Workshops kannst Du gezielt neue Techniken lernen und Deine Talente stärken.",
			to: "/workshops/",
		},
		{
			image: data.imageCardNavigationSpeaker,
			title: "Speaker",
			description:
				"Als Speaker nutzen Unternehmen meine Fähigkeiten der Kommunikation, Professionalität und den Charme des modernen Infotainment.",
			to: "/speaker/",
		},
		{
			image: data.imageCardNavigationCoaching,
			title: "Coaching",
			description: "Wer LEISTUNG will, muss SINN finden. Erkenne Dein WARUM!",
			to: "/coaching/",
		},
	];

	return (
		<Container>
			<div className="text-center">
				<SubTitle className="mb-2">Unser Leistungsspektrum</SubTitle>
				<H2 display className="mb-16">
					Investiere in Dich selbst
				</H2>
			</div>
			<Grid cols={3}>
				{cardNavigation.map((card) => {
					return (
						<CardNavigation
							title={card.title}
							description={card.description}
							to={card.to}
							imageData={card.image}
							alt=""
							key={card.title}
						/>
					);
				})}
			</Grid>
		</Container>
	);
};

export default InvestInYourSelf;
