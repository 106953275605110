// Packages
import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Types
import type { IGatsbyImageData } from "gatsby-plugin-image";

// Components
import Button from "~components/common/Button";
import H3 from "~components/typography/H3";
import P from "~components/typography/P";
interface Props {
	title: string;
	description: string;
	to: string;
	imageData: IGatsbyImageData;
	alt: string;
}

const CardNavigation: React.FC<Props> = ({ title, description, to, imageData, alt }) => {
	const image = getImage(imageData);
	return (
		<div>
			{image ? (
				<Link to={to}>
					<GatsbyImage image={image} className="mb-4 rounded" alt={alt} title={alt} />
				</Link>
			) : null}
			<H3 as="p" className="mb-4">
				{title}
			</H3>
			<P className="mb-4">{description}</P>
			<Button text="Mehr erfahren" type="transparent" to={to} className="px-0" />
		</div>
	);
};

export default CardNavigation;
