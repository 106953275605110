// Packages
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";

// Components
import Layout from "~components/layouts/Layout";
import PageHeader from "~components/sections/PageHeader";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import SubTitle from "~components/typography/SubTitle";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";
import Ul from "~components/typography/Ul";
import InvestInYourSelf from "~components/sections/InvestInYourSelf";
import Blockquote from "~components/typography/Blockquote";
import H3 from "~components/typography/H3";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	profileImage: IGatsbyImageData;
	infografik: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	const profileImage = getImage(data.profileImage);
	const infografik = getImage(data.infografik);

	return (
		<Layout
			location={location}
			title="Profil"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<PageHeader
				subtitle="Profil"
				title="Dein Coach Daniel Thiel"
				description={
					<div>
						{[
							"Freiheit",
							"Unabhängigkeit",
							"Lieben & Leben",
							"Werte",
							"1982",
							"Wirtschaftswissenschaften",
							"Ausbildung ",
							"DAX notiertes Unternehmen",
							"Investment",
							"Essen & Kochen",
							"Kulinarisch",
							"Reisen & Besichtigen",
							"Lesen",
							"Wellness & Erholung",
							"Sport",
							"Snowboard",
							"Windsurfen",
							"USA",
							"Kassel",
							"Humor",
							"Aktien & Fonds",
							"Familie & Freunde",
							"Vertrauen",
							"Spaß & Lachen",
							"Wissen & Lernen",
							"Wein & Cocktails",
							"Workshops & Coaching ",
							"Keynote Speaker",
							"Persönlichkeitsentwicklung",
							"Finanzen",
							"Immobilien",
							"Entspannung",
							"Empathie",
							"Kreativität",
							"Wachstum",
							"Partizipation",
							"Umsetzung & Verwirklichung",
							"Zukunftsorientiert",
							"Ziele",
							"Kreuzfahrten",
							"Kunst",
							"Infotainment",
							"Joggen",
							"Moderieren",
							"Freizeit",
							"Elvis Presley",
							"Musik",
							"Mentoring",
						].map((keyword) => (
							<span className="m-2 inline-flex items-center text-xs font-medium capitalize text-gray-400" key={keyword}>
								{keyword}
							</span>
						))}
					</div>
				}
			/>
			<Container>
				<Grid cols={2}>
					<div>
						<SubTitle className="mb-2">Profil</SubTitle>

						<H2 className="mb-4">Steckbrief</H2>
						<Ul
							items={[
								"Wirtschaftswissenschaftler",
								"Führungskraft",
								"Finanzexperte",
								"Business Coach",
								"Trainer für kognitive Lernfähigkeiten",
								"Keynote-Speaker",
							]}
						/>
					</div>
					<div>
						{profileImage ? (
							<GatsbyImage
								image={profileImage}
								alt="Daniel Tiel Profilbild"
								title="Daniel Thiel Profilbild"
								className="rounded"
							/>
						) : null}
					</div>
				</Grid>
			</Container>
			<Container>
				<Grid cols={2}>
					<div>
						{infografik ? (
							<GatsbyImage image={infografik} alt="Mein Was, Wie, Warum" title="Mein Was, Wie, Warum" />
						) : null}
					</div>
					<div>
						<SubTitle className="mb-2">Meine Motivation</SubTitle>
						<H2 className="mb-10">Wie ich mich selbst motiviere</H2>
						<H3 className="mb-4">Mein Warum</H3>
						<Blockquote
							text="Bei allem was ich mache, glaube ich daran Menschen besser zu machen und meinen Teil dazu beizutragen, dass Menschen ihr eigenes Potential erkennen."
							size="normal"
							align="left"
							className="mb-4"
						/>
						<P className="mb-3">
							Ich hatte in meiner Kindheit und in meinem bisherigen Leben sehr viel Liebe und Geborgenheit von meiner
							Familie erfahren. Dafür bin ich unfassbar dankbar.
						</P>
						<P className="mb-3">Diese Liebe erfahren zu dürfen, machte mich stark um für Menschen einzustehen. </P>
						<P className="mb-3">
							Menschen die nicht dieses Glück hatten, möchte ich etwas zurück zugeben. Sie zu inspirieren und sie besser
							zu machen, damit auch sie über sich hinauswachsen und ihr eigenes Potential erkennen, beseelt mich und
							treibt mich an.
						</P>
						<P className="mb-10">Wer ein Warum hat, dem ist kein Wie zu schwer.</P>
						<H3 className="mb-4">Mein Wie</H3>
						<P className="mb-10">
							Damit meine Kunden ihre eigenen Kompetenzen und Potentiale entwickeln, berate ich strategisch, veranstalte
							Workshops, bilde Mitarbeiter und Führungskräfte in Methoden und Mindsets aus, coache Teams oder packe auch
							selbst mit an wenn es um den Aufbau digitaler Units, neuer Produkte und Geschäftsmodelle geht. Das heißt,
							ich mache alles was meinen Kunden hilft ihre eigenen Kompetenzen zu entwickeln und ihrem eigenen Warum
							eine Definition zu geben. Damit fülle ich die Lücken, die der eigenen “Sinnerfüllung” meiner Kunden,
							dienlich sind. Das mache ich solange, bis die Hilfe zur eigenen Potentialentfaltung, Früchte trägt und ich
							meine Kunden ruhigen Gewissens “alleine laufen lassen” kann.
						</P>
						<H3 className="mb-4">Mein Was</H3>
						<P className="mb-3">
							Ich veranstalte Workshops, berate und coache, damit das Potential meiner Teilnehmer gefördert wird und ans
							Tageslicht kommt.
						</P>
						<P className="mb-3">
							Das eigene Potential in sich selbst zu erkennen und das eigene Warum zu kennen, ist oft der Schlüssel für
							die Erreichung der eigenen Ziele.
						</P>
						<P className="mb-3">
							Dafür benötigt man die richtigen Tools. Meine Teilnehmer wollen über sich hinauswachsen und sind bereit in
							sich selbst zu investieren, damit man für die wirklich wichtigen Dinge im Leben Zeit hat.{" "}
						</P>
						<P className="mb-3">Möchtest Du ein Teil unserer Commutity werden?</P>
						<P>Finde Deinen Weg und investiere in Dich selbst.</P>
					</div>
				</Grid>
			</Container>
			<InvestInYourSelf />
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		profileImage: file(relativePath: { eq: "pages/profil/profilbild_daniel-thiel.jpg" }) {
			childImageSharp {
				gatsbyImageData(quality: 90, aspectRatio: 1, transformOptions: { cropFocus: NORTH })
			}
		}
		infografik: file(relativePath: { eq: "pages/profil/was-wie-warum.png" }) {
			childImageSharp {
				gatsbyImageData(quality: 90)
			}
		}
	}
`;
